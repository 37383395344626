<template>
    <div>
        <ve-ring height="380px"
        :legend="{bottom:0}"
        :title="{
            show:true,
            text: salary_total,
            subtext: '总数',
            textStyle: {
                fontSize: 22,
                color: ['#23d9ae']
            },
            subtextStyle: {
                fontSize: 15
            },
            x: 'center',
            y: '50%'
        }"
        :extend="{
            series:{
                label: {
                    normal: {
                        formatter: params => {
                            return (
                                params.name +' '+ params.percent +'%'
                            );
                        }
                    }
                }
            }
        }"
        :data="easyData"></ve-ring>
        <div class="wrap">
            <div v-for="(item,key,index) in salary_sub" :key="index" class="wrap-item">
                <div class="wrap-color" style="width:30%">
                    <div :style='{background:color[index]}' class="wrap-circular"></div>
                </div>
                <div style="width:40%">{{key}}</div>
                <div style="width:30%">{{salary_sub[key]}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import VeRing from 'v-charts/lib/ring.common'
    export default {
        name:'salaryDetail',
        components:{
            [VeRing.name]: VeRing,
        },
        data() {
            return {
                bill_id:'',
                easyData: {},
                salary_sub:[],
                salary_total:'',
                color:[
                    "#19d4ae",
                    "#5ab1ef",
                    "#fa6e86",
                    "#ffb980",
                    "#0067a6",
                    "#c4b4e4",
                    "#d87a80",
                    "#9cbbff",
                    "#d9d0c7",
                    "#87a997",
                    "#d49ea2",
                    "#5b4947",
                    "#7ba3a8"
                ]
            }
        },
        created(){
            this.bill_id = this.$route.query.id
            this.getchartDatas()
        },
        methods:{
            getchartDatas() {
                this.$toast.loading({
                    message: '图表加载中...',
                    forbidClick: true
                })
                this.$api.employee_salaryBill({ bill_id:this.bill_id })
                .then(res => {
                    this.easyData = res.data.charts
                    this.salary_sub = res.data.salary_sub
                    this.salary_total = res.data.salary.salary_total
                })
                .finally(() => {
                    this.$toast.clear()
                })
            }
        },
        beforeRouteEnter(to, from, next) {
            document.title = to.query.name
            next()
        },
    }
</script>

<style lang="less" scoped>
    .wrap{
        width: 90%;
        border: 1px solid #ccc;
        margin: 0 auto;
        border-bottom: none;
        &-item{
            height: 50px;
            line-height: 50px;
            display: flex;
            border-bottom: 1px solid #ccc;
        }
        &-color{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-circular{
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

    }
</style>