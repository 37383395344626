var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ve-ring',{attrs:{"height":"380px","legend":{bottom:0},"title":{
        show:true,
        text: _vm.salary_total,
        subtext: '总数',
        textStyle: {
            fontSize: 22,
            color: ['#23d9ae']
        },
        subtextStyle: {
            fontSize: 15
        },
        x: 'center',
        y: '50%'
    },"extend":{
        series:{
            label: {
                normal: {
                    formatter: function (params) {
                        return (
                            params.name +' '+ params.percent +'%'
                        );
                    }
                }
            }
        }
    },"data":_vm.easyData}}),_c('div',{staticClass:"wrap"},_vm._l((_vm.salary_sub),function(item,key,index){return _c('div',{key:index,staticClass:"wrap-item"},[_c('div',{staticClass:"wrap-color",staticStyle:{"width":"30%"}},[_c('div',{staticClass:"wrap-circular",style:({background:_vm.color[index]})})]),_c('div',{staticStyle:{"width":"40%"}},[_vm._v(_vm._s(key))]),_c('div',{staticStyle:{"width":"30%"}},[_vm._v(_vm._s(_vm.salary_sub[key]))])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }