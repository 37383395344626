import { render, staticRenderFns } from "./salaryDetail.vue?vue&type=template&id=250d9604&scoped=true&"
import script from "./salaryDetail.vue?vue&type=script&lang=js&"
export * from "./salaryDetail.vue?vue&type=script&lang=js&"
import style0 from "./salaryDetail.vue?vue&type=style&index=0&id=250d9604&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "250d9604",
  null
  
)

export default component.exports